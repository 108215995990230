import { name as appName } from '../app.json';
import React from 'react';
import { AppRegistry, View } from 'react-native';
import { RootApp } from 'src/Root';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react'

// Initialize Bugsnag
Bugsnag.start({
  apiKey: '86bc7d2fd126ce0c1328de190e1b6104',
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = () => (
  <ErrorBoundary>
    <View style={{ minHeight: '100vh' }}>
      <RootApp />
    </View>
  </ErrorBoundary>
);

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
