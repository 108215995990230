import qs from 'qs';
import { Linking } from 'react-native';
import { ADMIN_EMAIL } from 'src/constants';


export async function sendEmail(to: string, subject: string, body: string) {

    let url = `mailto:${to}`;

    // Create email link query
    const query = qs.stringify({
        subject: subject,
        body: body,
    });

    if (query.length) {
        url += `?${query}`;
    }

    return Linking.openURL(url);
}

export async function sendAccountDeletionEmail(username: string | undefined, reason: string) {
    await sendEmail(ADMIN_EMAIL, 'Requesting Account Deletion',
        `I would like to delete my GRAMS account.\n\nUsername: ${username}\n\nReason: ${reason}.\n\nPlease delete my account as soon as possible. Thank you.`)
}