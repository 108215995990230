import React from 'react';
import LoginPlaceholder from '../../pages/LoginPlaceholder';
import LoginPage from '../../pages/LoginPage';
import SignupPage from '../../pages/SignupPage';
import ConfirmSignupPage from '../../pages/ConfirmSignupPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage';
import ForceChangePassword from '../../pages/ForceChangePassword';
import ProductReleasePage from '../../pages/ProductReleasePage';
import ConfirmForgotPasswordPage from '../../pages/ConfirmForgotPasswordPage';
import { NativeBaseProvider } from "native-base";
import { ToastProvider } from 'react-native-toast-notifications'
import { NavigationContainer } from '@react-navigation/native';
import CustomNavigationBar from '.././CustomNavigationBar'
import { nativeBaseTheme } from '../../theme'
import { useSelector } from 'react-redux';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getPlatform } from '../../utils/miscUtils'
import { Platform } from 'react-native';

const Stack = createNativeStackNavigator();

const linkingConfig = {
    config: {
        screens: {
            LoginPlaceholder: '/',
            LoginPage: '/login',
            SignupPage: '/signup',
            ForgotPasswordPage: '/forgot-password',
            ConfirmForgotPasswordPage: '/confirm-forgot-password',
            ConfirmSignupPage: '/confirm-signup',
            ForceChangePassword: '/force-change-password',
            ProductReleasePage: '/product-release',
        },
    },
};

const LoginStack = () => {
    const colorMode = useSelector((store) => store.app.colorMode);
    const theme = nativeBaseTheme(colorMode);

    return <ToastProvider>
        <NativeBaseProvider theme={theme}>
            <React.Fragment>
                {
                    Platform.OS === "web" ? <style type="text/css">{`
                    @font-face {
                      font-family: 'MaterialCommunityIcons';
                      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
                    }
                  `}</style> : null
                }
                <NavigationContainer linking={getPlatform() === "web" ? linkingConfig : null}>
                    <Stack.Navigator screenOptions={{
                        header: (props) => <CustomNavigationBar {...props} />,
                    }}>
                        <Stack.Screen name="LoginPlaceholder" component={LoginPlaceholder} options={{ headerShown: false, title: "Check login" }} />
                        <Stack.Screen name="LoginPage" component={LoginPage} options={{ headerShown: false, title: 'Login' }} />
                        <Stack.Screen name="SignupPage" component={SignupPage} options={{ headerShown: false, settings: false }} />
                        <Stack.Screen name="ForgotPasswordPage" component={ForgotPasswordPage} options={{ headerShown: false, title: 'Forgot password' }} />
                        <Stack.Screen name="ConfirmForgotPasswordPage" component={ConfirmForgotPasswordPage} options={{ headerShown: false, title: "Confirm forgot password" }} />
                        <Stack.Screen name="ConfirmSignupPage" component={ConfirmSignupPage} options={{ headerShown: false, title: "Confirm Signup" }} />
                        <Stack.Screen name="ForceChangePassword" component={ForceChangePassword} options={{ headerShown: false, title: "Change password" }} />
                        {
                            getPlatform() === "web" ? <Stack.Screen name="ProductReleasePage" component={ProductReleasePage} options={{ headerShown: false, title: "Product releases" }} /> : null
                        }
                    </Stack.Navigator>
                </NavigationContainer>
            </React.Fragment>
        </NativeBaseProvider>
    </ToastProvider>
}

export {
    LoginStack
}