import React from 'react';
import ChooseGrainProfilePage from '../../pages/ChooseGrainProfilePage';
import HomePage from '../../pages/HomePage';
import ChooseLoginTypePage from '../../pages/ChooseLoginTypePage';
import ChangePasswordPage from '../../pages/ChangePasswordPage';
import ConnectRpiPage from '../../pages/ConnectRpiPage';
import ConfigureRpiWifiPage from '../../pages/ConfigureRpiWifiPage';

import CustomNavigationBar from '../CustomNavigationBar'
import ProductReleasePage from '../../pages/ProductReleasePage';
import { NativeBaseProvider } from "native-base";
import { ToastProvider } from 'react-native-toast-notifications'
import { NavigationContainer } from '@react-navigation/native';
import { nativeBaseTheme } from '../../theme'
import { useSelector } from 'react-redux';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SettingsPage from 'src/pages/SettingsPage';
import { Platform } from 'react-native';
import { getPlatform } from 'src/utils/miscUtils';
import { isNative, getDocumentTitle } from 'src/utils/utils';
import UserGuidePage from 'src/pages/UserGuidePage';
const Stack = createNativeStackNavigator();

const linkingConfig = {
    config: {
        screens: {
            Home: '/',
            ChooseGrainProfile: '/choose-grain-profile',
            ChooseLoginTypePage: '/switch',
            ChangePasswordPage: '/change-password',
            ProductReleasePage: '/product-release',
            SettingsPage: '/settings',
            UserGuidePage : '/user-guide'
        },
    },
};

const AppUserForGrainAnalysisStack = () => {

    const colorMode = useSelector((store) => store.app.colorMode);
    const theme = nativeBaseTheme(colorMode);

    return <ToastProvider>
        <NativeBaseProvider theme={theme}>
            <React.Fragment>

                {
                    Platform.OS === "web" ? <style type="text/css">{`
                    @font-face {
                      font-family: 'MaterialCommunityIcons';
                      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
                    }
                  `}</style> : null
                }
                <NavigationContainer
                    documentTitle={{
                        formatter: (options, route) =>
                            getDocumentTitle()
                    }}
                    linking={getPlatform() === "web" ? linkingConfig : null}
                >
                    <Stack.Navigator screenOptions={{
                        header: (props) => <CustomNavigationBar {...props} />,
                    }}>
                        <Stack.Screen
                            name="ChooseLoginTypePage"
                            component={ChooseLoginTypePage}
                            options={{
                                showTitle: true,
                                title: 'Change Role',
                                settings: false
                            }} />
                        <Stack.Screen
                            name="ChooseGrainProfile"
                            component={ChooseGrainProfilePage}
                            options={{
                                showTitle: true,
                                title: 'Choose Grain Profile',
                                settings: false
                            }} />
                        <Stack.Screen
                            name="Home"
                            component={HomePage}
                            options={{
                                title: 'Home',
                                showTitle: false,
                                settings: true,
                                logo: true
                            }} />
                        {
                            isNative() ?
                                <>
                                    <Stack.Screen
                                        name="ConnectToRpi"
                                        component={ConnectRpiPage}
                                        options={{
                                            showTitle: true,
                                            title: 'Connect to GRAMS IoT device',
                                            settings: false
                                        }} />
                                    <Stack.Screen
                                        name="ConfigureRpiWifi"
                                        component={ConfigureRpiWifiPage}
                                        options={{
                                            showTitle: true,
                                            title: 'Configure IoT device WiFi',
                                            settings: false
                                        }} />
                                </> : null
                        }
                        <Stack.Screen
                            name="ChangePasswordPage"
                            component={ChangePasswordPage}
                            options={{
                                previous: true,
                                showTitle: true,
                                title: 'Change password',
                                settings: false
                            }} />
                        {
                            getPlatform() === "web" ?
                                <Stack.Screen
                                    name="ProductReleasePage"
                                    component={ProductReleasePage}
                                    options={{
                                        headerShown:
                                            false,
                                        title: "Product releases"
                                    }} />
                                : null
                        }
                        <Stack.Screen
                            name="SettingsPage"
                            component={SettingsPage}
                            options={{
                                showTitle: true,
                                headerShown: true,
                                title: "Settings",
                                previous: true
                            }} />
                        <Stack.Screen
                            name="UserGuidePage"
                            component={UserGuidePage}
                            options={{
                                showTitle: true,
                                headerShown: true,
                                title: 'User Guide',
                                previous: true,
                                }}
                        />
                    </Stack.Navigator>
                </NavigationContainer>
            </React.Fragment>
        </NativeBaseProvider>
    </ToastProvider>
}

export {
    AppUserForGrainAnalysisStack
}