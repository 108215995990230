const fieldMap: { [key: string]: string } = {
    "surveyorId": "surveyorId",
    "surveyorName": "name",
    "surveyorPhoneNumber": "phoneNumber",
}

const getMappedFieldName = (info_field_name: string) => {
    return fieldMap[info_field_name];
}

export {
    getMappedFieldName
}