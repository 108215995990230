import test_image from '../../../data/test_image.json'

class WindowsDownload {

    downloadFileFromUrl = async (url) => {
        window.electron.downloadFile(url)
    };
}

const windowsDownload = new WindowsDownload();

export default windowsDownload;