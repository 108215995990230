import { StyleSheet } from 'react-native';

export const cardStyle = StyleSheet.create({
    normalCard: {
        margin: 4,
    },
    bluetoothCard: {
        margin: 4,
        borderColor: 'gray',
        borderRadius: 4,
        elevation: 2,
        borderWidth: 1,
      }
})