import moment from "moment";
import { JobStatus } from "../models"

const getDisplayableJobId = (jobId: string) => {
    const jobIdArr = jobId.split("-");
    return jobIdArr[jobIdArr.length - 1];
}

const getBadgeColor = (status: JobStatus) => {
    if (status === JobStatus.JOB_IMAGE_UPLOADED) {
        return "gray";
    } else if (status === JobStatus.JOB_IN_PROGRESS) {
        return "orange";
    } else if (status === JobStatus.JOB_COMPLETED) {
        return "green";
    } else if (status === JobStatus.JOB_FAILED) {
        return "red";
    } else {
        return "gray"
    }
}

const isDisplayableJob = (status: JobStatus) => {
    return [
        JobStatus.JOB_IMAGE_UPLOADED,
        JobStatus.JOB_IN_PROGRESS,
        JobStatus.JOB_COMPLETED,
        JobStatus.JOB_FAILED
    ].includes(status)
}

const getStatusMessage = (status: JobStatus) => {
    if (status === JobStatus.JOB_IMAGE_UPLOADED) {
        return "UPLOADED";
    } else if (status === JobStatus.JOB_IN_PROGRESS) {
        return "ANALYZING";
    } else if (status === JobStatus.JOB_COMPLETED) {
        return "COMPLETED";
    } else if (status === JobStatus.JOB_FAILED) {
        return "FAILED";
    } else {
        return "UNKNOWN";
    }
}

const getFormattedDate = (dateInEpoch: number) => {
    return moment(new Date(dateInEpoch * 1000)).format('D MMM, YY HH:mma')
}

export {
    getBadgeColor,
    getDisplayableJobId,
    isDisplayableJob,
    getStatusMessage,
    getFormattedDate
}