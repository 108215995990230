import { getPlatform } from "../miscUtils";
import windowsDownload from "../../services/WindowsDownload";

export const downloadImageToFs = async (image_url: string, sample_id: string) => {
    if(getPlatform() === 'windows') {
        try {
            windowsDownload.downloadFileFromUrl(image_url)
        } catch(ex) {
            console.log('error occurred in windows download')
        }
    } else {
        await downloadFile(image_url)
    }
}

export const downloadPdfToFs = async (report_url: string, job_id: string) => {
    if(getPlatform() === 'windows') {
        try {
            windowsDownload.downloadFileFromUrl(report_url)
        } catch(ex) {
            console.log('error occurred in windows download')
        }
    } else {
        window.open(report_url, 'Download');
    }
}

const downloadFile = async (url: string) => {
    fetch(url, {
        method: "GET",
        headers: {}
    })
        .then(response => {
            const file_name = url.split('/')[url.split('/').length - 1]
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file_name); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        })
        .catch(err => {
            console.log(err);
        });
}