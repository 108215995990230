import uuid from 'react-native-uuid';
import { PLATFORM } from "../../constants"

export const getDeviceId = async () => {
    const deviceId = uuid.v4().toString()
    return deviceId
};

export const getPlatform = () => {
    return PLATFORM // web / windows
}