import test_image from '../../../data/test_image.json'

class WindowsScan {

    scanImageWindows = async () => {
        const fileData = await window.electron.wiaScan()
        return fileData
    };

    scanImageWindowsTest = async () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('returning test image');
                resolve(test_image['src']);
            }, 10000);
        });
    };
}

const windowsScan = new WindowsScan();

export default windowsScan;