import { StyleSheet } from 'react-native';

export const listStyle = StyleSheet.create({
    footer: {
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    subTitleText: {
        marginTop: 4,
        fontSize: 12,
        justifyContent: 'center'
    },
    subTitleBoldText: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: 'bold',
        justifyContent: 'center'
    },
    iconText: {
        flexDirection: 'row'
    },
    cardSection: {
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    avatar: {
    },
    title: {
        fontSize: 12,
    },
    textWithIcon: {
        fontSize: 12,
    },
    largeTitle: {
        fontSize: 22, 
        fontWeight: 'bold'
    },
    largeIcon: {
        marginTop: 4,
        justifyContent: 'center'
    },
    largeSubTitleText: {
        marginTop: 4,
        fontSize: 20,
        justifyContent: 'center'
    },
    largeSubTitleBoldText: {
        marginTop: 4,
        fontSize: 20,
        fontWeight: 'bold',
        justifyContent: 'center'
    },
    
})