import { StyleSheet } from 'react-native';

export const containerStyle = StyleSheet.create({
    formContainer: {
        marginTop: 12,
        marginBottom: 36,
        justifyContent: 'center',
        alignSelf: 'center',
    },
    mainContainer: {
        flex: 1,
        padding: 8,
        paddingTop: 8,
    },
    listContainer: {
        flex: 1,
    },
    logoContainer: {
        width: '100%',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: 8,
        marginBottom: 48
    },
    imageContainer: {
        padding: 20,
        height: "100%",
        width: "100%",
        alignSelf: 'center'
    },
    modalContainer: {
        padding: 20,
        height: "90%",
        width: "90%",
        alignSelf: 'center'
    },
    componentContainer: {
        marginTop: 12,
        marginBottom: 12,
    },
    zoomedIndiGrainImageContainer: {
        width: "100%",
        alignContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: "100%"
    },
    tableContainer: { padding: 4, paddingTop: 4 },
    resultActionsBarStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 12
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
      },
      thumbnailContainer: {
        width: '10%',
        marginRight:  16,
      },
      thumbnail: {
        width: '100%',
        height: undefined,
        aspectRatio: 1,
        borderRadius: 8,
      },
      textContainer: {
        flex: 1,
        width: '80%',
        alignItems:'flex-start',
        marginLeft:  16,
      },
})