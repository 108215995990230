import { StyleSheet } from 'react-native';

export const inputStyle = StyleSheet.create({
    inputIcon: {
        marginTop: 14
    },
    nativeInput: {
        height: 48,
    },
    measurementInput: {
        height: 40,
    }
})