import { StyleSheet } from 'react-native';

export const textStyle = StyleSheet.create({
    sectionText: {
        margin: 12,
        fontSize: 20,
        fontWeight: 'bold'
    },
    subSectionText: {
        marginTop: 12,
        fontSize: 14,
        fontWeight: 'bold'
    },
    tableTitleText: {
        marginBottom: 8,
        marginTop: 16,
        fontSize: 16,
        fontWeight: 'bold'
    },
    measurementSectionText: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    statisticsTitle: {
        fontSize: 12,
        textAlign: 'left'
    },
    inputLabel: {
        marginTop: 2,
        fontSize: 11,
    },
    title: {
        fontSize: 18,
        color:"#666",
        fontWeight: 'bold',
    },
    description: {
        fontSize: 14,
        color: '#555',
    }
    
})