import React from 'react';
import ChooseLoginTypePage from '../../pages/ChooseLoginTypePage';
import { NativeBaseProvider } from "native-base";
import { ToastProvider } from 'react-native-toast-notifications'
import { NavigationContainer } from '@react-navigation/native';
import { nativeBaseTheme } from '../../theme'

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useSelector } from 'react-redux';

const Stack = createNativeStackNavigator();

const StackChooser = () => {
    
    const colorMode = useSelector((store) => store.app.colorMode);
    const theme = nativeBaseTheme(colorMode);

    return <ToastProvider>
        <NativeBaseProvider theme={theme}>
            <React.Fragment>
                <NavigationContainer>
                    <Stack.Navigator>
                        <Stack.Screen name="ChooseLoginTypePage" component={ChooseLoginTypePage} options={{ showTitle: true, title: 'Change Role', settings: false }} />
                    </Stack.Navigator>
                </NavigationContainer>
            </React.Fragment>
        </NativeBaseProvider>
    </ToastProvider>
}

export {
    StackChooser
}