import { StyleSheet } from 'react-native';

export const tableStyle = StyleSheet.create({
    head: {
        height: 60,
        backgroundColor: '#f1f8ff',
        flexDirection: 'row'
    },
    row: {
        flexDirection: 'row',
    },
    cellText: {
        margin: 6,
    },
    elementText: {
        fontStyle: 'italic'
    },
    border: {
        borderWidth: 2,
        borderColor: '#c8e1ff'
    },
    input: {
        margin: 6,
    },
    mainHead: {
        height: 60,
        backgroundColor: '#a3b18a',
        flexDirection: 'row'
    },
    mainBorder: {
        borderWidth: 0.5,
        borderColor: '#000'
    },
    mainRow: {
        backgroundColor: '#a3b18a',
        flexDirection: 'row',
    },
    mainCellHead: {
        fontSize: 16,
        fontWeight: 'bold',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    mainCellText: {
        fontSize: 16,
        fontWeight: 'bold',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 12,
        paddingBottom: 12,
    },
})